import React, { useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';

import CloseIcon from '@icons/close.inline.svg';
import NavigationMenus from './navigation_menus';

const NavigationOverlay = (props) => {

    return(
        <div className={classNames('navigation', {'is-active': props.active, 'is-expanded': props.isExpanded})}>
            <div className="navigation__overlay" role="button" tabIndex={0} onClick={props.handleClick} onKeyDown={props.handleClick}>Navigation Overlay</div>
            <div className={classNames('navigation__close', {'is-active': props.active})} role="button" tabIndex={0} onClick={props.handleClose} onKeyDown={props.handleClose}>
                <CloseIcon />
            </div>
            <div className="navigation__inner">
                <Navbar expand={false}>
                    <Nav className="nav">
                        <NavigationMenus
                            handleExpand={props.handleExpand}
                            handleClose={props.handleClose}
                        />
                    </Nav>
                </Navbar>
            </div>
        </div>
    )
}

export default NavigationOverlay;