import React from 'react';

import FacebookIcon from '@icons/facebook.inline.svg';
import TwitterIcon from '@icons/twitter.inline.svg';
import LinkedInIcon from '@icons/linkedIn.inline.svg';
import InstagramIcon from '@icons/instagram.inline.svg';
import YoutubeIcon from '@icons/youtube.inline.svg';

const FooterSocialLinks = (props) => {
    return (
        <div className={props.socialClass}>
            <ul className={`list-inline${props.socialUlClass}`}>
                <li className="list-inline-item"><a href="https://www.facebook.com/cruxcareers/" target="_blank" rel="noreferrer" aria-label="Facebook page"><FacebookIcon /></a></li>
                <li className="list-inline-item"><a href="https://twitter.com/cruxcareers" target="_blank" rel="noreferrer" aria-label="Twitter page"><TwitterIcon /></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/cruxcareers/" target="_blank" rel="noreferrer" aria-label="Instagram page"><InstagramIcon /></a></li>
                <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCa8GzXQyDqViIbOsVASAsrg" target="_blank" rel="noreferrer" aria-label="YouTube page"><YoutubeIcon /></a></li>
                <li className="list-inline-item"><a href="https://www.linkedin.com/company/crux-careers/" target="_blank" rel="noreferrer" aria-label="LinkedIn page"><LinkedInIcon /></a></li>
            </ul>
        </div>
    );
}

export default FooterSocialLinks