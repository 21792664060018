import React from 'react';
import classNames from 'classnames';

const NavigationButton = (props) => {
    return(
        <div className={classNames('icon-holder toggle-nav', {'is-active': props.active})} onClick={props.handleClose} onKeyDown={props.handleClose} role="button" tabIndex={0}>
            Navigation Button
            <span></span>
        </div>
    )
}

export default NavigationButton;