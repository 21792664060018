import React from "react";

import NavLinks from '@components/navigation/navigation_link.js';

const NavLinkCont = (props) => (
    <>
        {props.navData.map((link, index) => {
            if (link.node.Parent === null) { // Only show parent links
                return(
                    <NavLinks
                        key={index}
                        ButtonData={[link.node.menuLink]}
                        btnClass="btn-none"
                    />
                )
            } else {
                return null;
            }
        })}
    </>
)

export default NavLinkCont