import React from 'react';
import SiteByStarberry from './SiteByStarberry';

const FooterCopyright = (props) => {
    return (
        <div className={props.copyrightClass}>
            <ul className="list-inline footer__copyright__links">
                <li className="list-inline-item"><a href="/terms-and-conditions/">Terms & Conditions</a></li>
                <li className="list-inline-item"><a href="/privacy-policy/">Privacy Policy</a></li>
                <li className="list-inline-item"><a href="/sitemap/">Sitemap</a></li>
                <li className="list-inline-item"> <a href="javascript:void(0);" id="open_preferences_center">Update Cookies Preferences</a></li>
               
            </ul>
            Copyright © {new Date().getFullYear()} Crux Careers. <br className="d-block d-sm-none" />All Righters Reserved.<br />
            <SiteByStarberry />
        </div>
    );
}

export default FooterCopyright