import React from "react";

import NavigationParentLink from "./navigation_parent_link"


const NavigationLinkParent = (props) => (
    <>
        {props.menu.map((link) => {
            return(
                <NavigationParentLink
                    key={link.id}
                    link={link}
                    ButtonData={[link.menuLink]}
                    btnClass="btn-none"
                    handleExpand={props.handleExpand}
                    handleClose={props.handleClose}
                />
            )
        })}
    </>

)

export default NavigationLinkParent