import React from 'react';

const Icon = ({children, classNameIcon = ""}) => {
  return (
    <span className={`icon-holder ${classNameIcon}`}>
      {children}
    </span>
  )
}

export default Icon;
