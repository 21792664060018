import React from 'react';

import StarberryIcon from '@icons/siteby.inline.svg';

const SiteByStarberry = (props) => {
    return (
        <a href="https://starberry.tv/" className="siteby" target="_blank" rel="noreferrer">Site by <StarberryIcon /></a>
    );
}

export default SiteByStarberry;