import React from "react";
import { NavDropdown } from 'react-bootstrap';

import NavigationLink from "./navigation_link"
import NavigationLinkDropdown from "./navigation_dropdown"

const NavigationParentLink = (props) => {
    return(
    <>
        {/* if the link doesn't have child nodes it means its only a parent link */}
        {props.link.childNodes.length === 0 &&
            <NavigationLink
                ButtonData={[props.link.menuLink]}
                btnClass="btn-none"
            />
        }
        {props.link.childNodes.length > 0 &&
            <NavDropdown href="#" title={props.link.menuLink.Label} onClick={props.handleExpand}>
                <NavDropdown.Item className="back" role="button" tabIndex={0} onClick={props.handleClose} onKeyDown={props.handleClose}>&lt; Back</NavDropdown.Item>
                {/* We need to add and Overview dropdown link as the parent link is not clickable */}
                <NavigationLink
                    linkTag="NavDropdown.Item"
                    ButtonData={[props.link.menuLink]}
                    btnClass="btn-none"
                    addLabelText=" Overview"
                    link={null}
                />
                {/* Here we render the child records od the parent link to create 2nd level dropdowns */}
                {props.link.childNodes.map((link) => {
                    return(
                        <NavigationLinkDropdown
                            key={link.id}
                            link={link}
                            handleExpand={props.handleExpand}
                            handleClose={props.handleClose}
                        />
                    )
                })}
            </NavDropdown>
        }
    </>
)
}

export default NavigationParentLink