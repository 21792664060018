import React, { Fragment } from "react";
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from "gatsby";

const NavigationLink = (props) => (
    <>
        {props.ButtonData.map((btn, index) => {
            if (btn.page) { // if its a normal internal link
                if (props.linkTag === "NavDropdown.Item") {
                    return(
                        <Fragment key={index}>
                            <NavDropdown.Item href={`${props.link && props.link.Parent && props.link.Parent.menuLink && props.link.Parent.menuLink.page ? '/'+props.link.Parent.menuLink.page.Slug+'/'+props.link.menuLink.page.Slug+'/' : '/'+btn.page.Slug+'/'}`} className={props.btnClass}>
                                {btn.Label}{props.addLabelText && props.addLabelText}
                            </NavDropdown.Item>
                        </Fragment>
                    )
                } else {
                    return(
                        <Fragment key={index}>
                            <Link to={`/${btn.page.Slug}/`} activeClassName="active" className={`nav-link ${props.btnClass}`}>
                                {btn.Label}
                            </Link>
                        </Fragment>
                    )
                }
                
            } else if (btn.targetLink) { // using href target link to scroll to ID or add relative URL
                if (props.linkTag === "NavDropdown.Item") {
                    return(
                        <Fragment key={index}>
                            <NavDropdown.Item href={`${btn.targetLink}/`} className={props.btnClass}>
                                {btn.Label}{props.addLabelText && props.addLabelText}
                            </NavDropdown.Item>
                        </Fragment>
                    )
                } else {
                    return(
                        <Fragment key={index}>
                            <Link to={`${btn.targetLink}/`} activeClassName="active" className={`nav-link ${props.btnClass}`}>
                                {btn.Label}
                            </Link>
                        </Fragment>
                    )
                }
            } else if (btn.externalLink) { // using external link
                if (props.linkTag === "NavDropdown.Item") {
                    return(
                        <Fragment key={index}>
                            <NavDropdown.Item href={`${btn.externalLink}/`} className={props.btnClass} target="_blank" rel="noreferrer">
                                {btn.Label}{props.addLabelText && props.addLabelText}
                            </NavDropdown.Item>
                        </Fragment>
                    )
                } else {
                    return(
                        <Fragment key={index}>
                            <Nav.Link href={`${btn.externalLink}/`} className={props.btnClass} target="_blank" rel="noreferrer">
                                {btn.Label}
                            </Nav.Link>
                        </Fragment>
                    )
                }
            } else {
                return null
            }
        })}
    </>
)

export default NavigationLink