import React, { useState, Fragment } from 'react';

import  './assets/_index.scss';

import NavigationButton from './navigation_button';
import NavigationOverlay from './navigation_overlay';
/**
 * Context API
 */
// import { useGlobalState } from '@hooks/hooks';

/**
 * Assest
 */


const Navigation = ({active, navigationToggle}) => {
    const [expanded, setExpanded] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const handleClick = () => {
        navigationToggle();
        setExpanded(false);
    };

    const handleExpand = (event) => {
        //setExpanded(!event.target.parentNode.classList.contains('show'));
        setTimeout(function(){
            setIsExpanded(true);
        },500)
        
        
    }
    const handleClose = () => {
        setIsExpanded(false);
        if(expanded) { 
            setExpanded(false);
        } else { 
             navigationToggle(); 
        }
    }

    return (
        <Fragment>
            <NavigationButton
                active={active}
                handleClose={handleClose}
            />
            <NavigationOverlay
                active={active}
                handleClick={handleClick}
                handleClose={handleClose}
                handleExpand={handleExpand}
                expanded={expanded}
                isExpanded={isExpanded}
            />
        </Fragment>
    )
}

export default Navigation;
