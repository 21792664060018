import { useEffect } from 'react';

export default function LoadExternalScript(props) {
    let src = props.src || '';
    let async = props.async || "";
    let defer = props.defer || "";
    let loadScript = props.loadScript || '';
    console.log(src);
    console.log(async);
    console.log(defer);

    // Ref: https://usehooks.com/useScript/
    useEffect(
        () => {

            if (!src) {
                console.log('no script');
                return;
            }

            // Fetch existing script element by src
            // It may have been added by another intance of this hook
            let script = document.querySelector(`script[src="${src}"]`);
            
            if (!script) {
                script = document.createElement("script"); // Create script
                script.src = src;
                if (async !== "") {
                    script.async = async;
                }
                if (defer !== "") {
                    script.defer = defer;
                }
                document.body.appendChild(script); // Add script to document body
                // console.log('script ' + src + ' is loaded');
                if (loadScript) {
                    // console.log(loadScript);
                    script.onload = loadScript;
                    // console.log('loadScript is done');
                }
            }

            // Clean up
            return () => {
                if (script) {
                    document.body.removeChild(script); // Remove script from document body
                    // console.log('script ' + src + ' is removed');
                }
            };
      },
      [src] // Only re-run effect if script src changes
    );

    // export default function should return something,
    // otherwise, it will throw error as like the below,
    // "Nothing was returned from render.
    // This usually means a return statement is missing.
    // Or, to render nothing, return null"
    return null;

}