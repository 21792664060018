import React from 'react';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import OpenIcon from '@icons/plus.inline.svg';
import CloseIcon from '@icons/minus.inline.svg';

function ToggleIcon({ children, eventKey, handleClick, toggleClassName }) {
    const toggleOnClick = useAccordionToggle(eventKey, () => {
        handleClick();
    });

    return (
      <span
        role='button'
        tabIndex='0'
        className={`toggleBtn${toggleClassName}`}
        onClick={toggleOnClick}
        onKeyDown={toggleOnClick}
      >
        {children}
      </span>
    );
}

const FooterColHeader = (props) => {

    return (
        <ToggleIcon
            as={Card.Header}
            eventKey={props.index}
            toggleClassName={props.toggleSpacing}
            handleClick={() => {
                (props.activeKey === props.index) ? props.setActiveKey(null) : props.setActiveKey(props.index);
            }}
        >
            <span>{props.toggleName}</span>
            {props.activeKey === props.index ? <CloseIcon /> : <OpenIcon />}
        </ToggleIcon>
    );
}

export default FooterColHeader;