import React from 'react';
import LoadExternalScript from "../utils/load-external-script";
const writeView = 'https://www.kerfuffle.com/media/stb/template/js/review-widget.js?view=embed&obj=content&id=28&t=1619006358108&sd=1';

const IframeReview = (props) => {
    return (
        <div className={props.reviewClass}>
                   <LoadExternalScript 
              src={writeView}
              // async="true"
              defer="true"
            //   loadScript={renderMap}
              // appendScriptTo="head"
            />
            <div id="review-embed-code"></div>
        </div>
    );
}

export default IframeReview