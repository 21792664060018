import React from "react";

import NavigationLink from "./navigation_link"

const NavigationDropdown = (props) => (
    <>
        <NavigationLink
            linkTag="NavDropdown.Item"
            ButtonData={[props.link.menuLink]}
            link={props.link}
            btnClass="btn-none"
        />
    </>
)

export default NavigationDropdown